import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WatFocus = _resolveComponent("WatFocus")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          (!_ctx.showExitPage)
            ? (_openBlock(), _createBlock(_component_WatFocus, {
                key: 0,
                isUser: true,
                isPublic: _ctx.isPublic,
                nickname: _ctx.nickname,
                visitorToken: _ctx.visitorToken,
                testerAuthToken: _ctx.testerAuthToken,
                onExit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.exit()))
              }, null, 8 /* PROPS */, ["isPublic", "nickname", "visitorToken", "testerAuthToken"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}