
import { defineComponent } from 'vue'
import WatFocus from '~/components/WatFocus/WatFocus.vue'
import authStore from '@/store/auth.store'
import Swal from 'sweetalert2'
import {
    IonPage,
    IonContent,
} from '@ionic/vue'
import store from '@/store'
export default defineComponent({
    name: 'WatFocusExecution',
    props:{
        isPublic:{
            type: Boolean,
            default: false
        }
    },
    components:{
        IonPage,
        IonContent,
        WatFocus
    },
    data(){
        return{
            nickname: "",
            visitorToken:"",
            testerAuthToken:"",
            showExitPage: false
        }
    },
    methods:{
        async fetchPublicTester(){
            await store.dispatch("FETCH_FOCUS_TESTER",{visitorToken: this.$route.params.auth,id: this.$route.params.id, slotHash: this.$route.params.slotHash})
            .then((data) => {
                this.nickname = data.visitorToken
            })
        },
        exit(){
            if(this.isPublic){
                this.showExitPage = true
                let vm = this
                Swal.fire({
                    title: vm.$t('wat_focus_tester_retry_msg'),
                    icon: "success",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCloseButton: false,
                    confirmButtonText: vm.$t('wat_focus_tester_retry'),
                    confirmButtonColor: "#3085d6"
                }).then(() => {
                    vm.showExitPage = false
                });

            }
        }
    },
    async created(){
        if(this.isPublic){
            this.visitorToken = Array.isArray(this.$route.params.auth) ? this.$route.params.auth[0] : this.$route.params.auth as string;
            await this.fetchPublicTester()
        }
        else{
            this.nickname = authStore.state.userData.nickname
            this.testerAuthToken = authStore.state.authToken
        }
    }
})
